<template>
  <section tabindex="0" id="portfolio" class="card w-full">
    <div class="mb-8 prose-freelance">
      <h2>{{ portfolio.title }}</h2>
      <p>{{ portfolio.text }}</p>
      <a
        :title="meta.downloadText"
        :href="meta.downloadLink"
        class="no-print mt-6 flex items-center text-lg leading-6 text-bold no-underline hover:underline freelance-text-action"
        ><DocumentArrowDownIcon class="w-12 h-12 mr-3" />{{
          meta.downloadText
        }}</a
      >
    </div>
    <ul aria-label="list of references" class="bg-transparent p-0 list-none">
      <li
        :key="idx"
        v-for="(reference, idx) in references"
        class="reference-card"
      >
        <a
          aria-label="show reference"
          @click="showImages(reference.images)"
          class="flex flex-wrap sm:flex-row items-center justify-center"
        >
          <div
            class="bg-zinc-700 w-full h-24 sm:h-40 relative mb-4 overflow-hidden"
          >
            <div
              class="mx-auto sm:absolute z-10 right-4 w-40 h-16 bg-zinc-700 my-4 rounded-md flex items-center"
            >
              <img
                :src="reference.clientLogo"
                :alt="reference.clientLogoAlt"
                width="200"
                height="100"
              />
            </div>
            <img
              :src="reference.images[0].mainUrl"
              :alt="reference.images[0].description"
              class="opacity-20"
            />
          </div>
          <div class="sm:ml-4 w-full">
            <h3>{{ reference.title }}</h3>
            <h4>{{ reference.subtitle }}</h4>
          </div>
        </a>
      </li>
    </ul>
    <h3 class="text-center freelance-color-600 mb-8 mt-16 new-page">
      {{ portfolio.logoHeading }}
    </h3>
    <div
      class="grid grid-cols-2 bg-zinc-800 dark:bg-transparent rounded-md p-4 dark:p-0"
    >
      <img
        :key="logo.alt"
        v-for="logo in portfolio.logowall"
        :src="logo.url"
        :alt="logo.alt"
        width="200"
        height="150"
      />
    </div>
  </section>
</template>

<script setup>
import { defineProps } from "vue";
import ImageViewer from "awesome-image-viewer";
import { DocumentArrowDownIcon } from "@heroicons/vue/24/outline";
defineProps({
  portfolio: {
    type: Object,
    required: true,
  },
  references: {
    type: Array,
    required: true,
  },
  meta: {
    type: Object,
    required: true,
  },
});

function showImages(imageSet) {
  new ImageViewer({
    images: imageSet,
    showThumbnails: false,
  });
}
</script>

<style></style>
