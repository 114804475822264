<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.25H15C15.5967 17.25 16.169 17.0129 16.591 16.591C17.0129 16.169 17.25 15.5967 17.25 15V3C17.25 2.40326 17.0129 1.83097 16.591 1.40901C16.169 0.987053 15.5967 0.75 15 0.75H3C2.40326 0.75 1.83097 0.987053 1.40901 1.40901C0.987053 1.83097 0.75 2.40326 0.75 3V15C0.75 15.5967 0.987053 16.169 1.40901 16.591C1.83097 17.0129 2.40326 17.25 3 17.25Z"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.31702 6.738H9.76502V7.86H9.79902C9.87835 7.70133 9.99168 7.54267 10.139 7.384C10.2863 7.22533 10.462 7.08367 10.666 6.959C10.87 6.83433 11.1023 6.73233 11.363 6.653C11.6237 6.57367 11.907 6.534 12.213 6.534C12.859 6.534 13.3803 6.636 13.777 6.84C14.1737 7.03267 14.4797 7.30467 14.695 7.656C14.9217 8.00733 15.0747 8.421 15.154 8.897C15.2333 9.373 15.273 9.88867 15.273 10.444V15H12.723V10.954C12.723 10.716 12.7117 10.4723 12.689 10.223C12.6777 9.96233 12.6267 9.72433 12.536 9.509C12.4567 9.29367 12.3263 9.118 12.145 8.982C11.975 8.846 11.7257 8.778 11.397 8.778C11.0683 8.778 10.802 8.84033 10.598 8.965C10.394 9.07833 10.2353 9.237 10.122 9.441C10.02 9.63367 9.95202 9.85467 9.91802 10.104C9.88402 10.3533 9.86702 10.614 9.86702 10.886V15H7.31702V6.738Z"
      fill="currentColor"
    />
    <path
      d="M2.935 6.738H5.485V15H2.935V6.738ZM2.731 4.137C2.731 3.729 2.87267 3.38333 3.156 3.1C3.45067 2.80533 3.802 2.658 4.21 2.658C4.618 2.658 4.96367 2.80533 5.247 3.1C5.54167 3.38333 5.689 3.729 5.689 4.137C5.689 4.545 5.54167 4.89633 5.247 5.191C4.96367 5.47433 4.618 5.616 4.21 5.616C3.802 5.616 3.45067 5.47433 3.156 5.191C2.87267 4.89633 2.731 4.545 2.731 4.137Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
