<template>
  <section tabindex="0" id="skills" class="card w-full prose-freelance">
    <h2>{{ skills.title }}</h2>
    <p>{{ skills.text }}</p>
    <div
      :key="skillSet.title"
      v-for="skillSet in skills.skillSets"
      class="mt-4"
    >
      <div class="flex">
        <component
          :is="getIconComponent(skillSet.icon)"
          class="w-10 h-10 mr-4 my-3 opacity-50"
        />
        <h3 class="mt-4">{{ skillSet.title }}</h3>
      </div>

      <ul v-if="skillSet.list" class="list-none p-0 grid">
        <li
          :key="skill.label"
          v-for="skill in skillSet.list"
          class="p-0 flex w-full items-start mt-3 text-md freelance-text-light"
        >
          <span class="w-4/5 font-bold">
            {{ skill.name }}
          </span>
          <div class="flex w-1/5">
            <component
              :is="getIconComponent(skill.icon)"
              class="w-6 h-6 ml-auto my-1 freelance-text-action"
            />
          </div>
        </li>
      </ul>

      <ul v-if="skillSet.skills" class="list-none p-0 grid">
        <li
          :key="skill.label"
          v-for="skill in skillSet.skills"
          class="p-0 flex flex-col mb-8 mt-3 sm:flex-row sm:mb-2 w-full items-center text-md freelance-text-light"
        >
          <span class="w-full sm:w-1/2 font-bold mb-2">
            {{ skill.name }}
          </span>
          <span class="w-full sm:w-1/2 freelance-bg-900 rounded-md inline-flex">
            <span
              class="h-2 rounded-full freelance-bg-action inline-flex"
              :style="`width:${skill.level}0%`"
            ></span>
          </span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
import { defineProps } from "vue";
defineProps({
  skills: {
    type: Object,
    required: true,
  },
});

import {
  HeartIcon,
  CheckIcon,
  MapPinIcon,
  MapIcon,
  UserCircleIcon,
  MicrophoneIcon,
  CodeBracketSquareIcon,
  ComputerDesktopIcon,
  RectangleGroupIcon,
  SwatchIcon,
} from "@heroicons/vue/24/outline";
const iconList = {
  HeartIcon,
  CheckIcon,
  MapPinIcon,
  MapIcon,
  UserCircleIcon,
  MicrophoneIcon,
  CodeBracketSquareIcon,
  ComputerDesktopIcon,
  RectangleGroupIcon,
  SwatchIcon,
};

const getIconComponent = (iconName) => {
  return iconList[iconName] || null;
};
</script>

<style></style>
