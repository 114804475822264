<template>
  <section tabindex="0" id="about" class="card w-full prose-freelance">
    <h2>{{ about.title }}</h2>
    <p>{{ about.text }}</p>
    <ul class="list-none p-0">
      <li
        :key="attribute.text"
        v-for="attribute in about.attributes"
        class="p-0 flex items-center text-md freelance-text-light"
      >
        <component
          :is="getIconComponent(attribute.icon)"
          class="w-10 h-10 mr-4 my-3 opacity-50"
        />
        <div class="w-4/5 ml-auto">
          {{ attribute.text }}
        </div>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { defineProps } from "vue";
defineProps({
  about: {
    type: Object,
    required: true,
  },
});

import {
  MapPinIcon,
  UserCircleIcon,
  MicrophoneIcon,
} from "@heroicons/vue/24/outline";
const iconList = {
  MapPinIcon,
  UserCircleIcon,
  MicrophoneIcon,
};

const getIconComponent = (iconName) => {
  return iconList[iconName] || null;
};
</script>

<style></style>
