<template>
  <aside class="sidebar sm:rounded-xl px-2 sm:px-4 py-2 sm:py-8 transition-all">
    <nav class="">
      <ul class="w-full flex flex-wrap sm:flex-col">
        <li
          :key="navItem.label"
          v-for="navItem in nav"
          class="p-4 z-20 inline-flex items-center freelance-text-action sm:freelance-text font-bold text-md sm:text-lg hover:freelance-bg-action hover:text-white hover:cursor-pointer rounded-lg"
          @click="scrollMeTo(navItem.link)"
        >
          <component
            :is="getIconComponent(navItem.icon)"
            class="hidden sm:inline sm:h-6 sm:w-6 freelance-text mr-2 sm:mr-4 opacity-80"
          />
          <div :aria-label="navItem.label">
            {{ navItem.label }}
          </div>
        </li>
        <li v-if="currentLang === 'de'" class="sm:ml-auto text-md w-full">
          <div
            class="languageButton"
            role="button"
            @click="$emit('currentLang', 'en')"
          >
            in english please
          </div>
        </li>
        <li v-if="currentLang === 'en'" class="sm:ml-auto text-md w-full">
          <div
            class="languageButton"
            role="button"
            @click="$emit('currentLang', 'de')"
          >
            auf deutsch bitte
          </div>
        </li>
      </ul>
      <div class="sidebar-bubble z-0"></div>
    </nav>
  </aside>
</template>

<script setup>
import { defineProps } from "vue";
import { scrollMeTo } from "./../utils/scrollMeTo.js";

import {
  EnvelopeIcon,
  UserCircleIcon,
  ChatBubbleLeftRightIcon,
  TvIcon,
  TrophyIcon,
  CurrencyEuroIcon,
  BanknotesIcon,
  AcademicCapIcon,
  FireIcon,
  ComputerDesktopIcon,
  // BarsArrowDownIcon,
} from "@heroicons/vue/24/outline";

defineProps({
  nav: Array,
  currentLang: String,
});
// const menuOpen = ref(false);
const icons = {
  EnvelopeIcon,
  ChatBubbleLeftRightIcon,
  UserCircleIcon,
  TvIcon,
  TrophyIcon,
  CurrencyEuroIcon,
  BanknotesIcon,
  AcademicCapIcon,
  FireIcon,
  ComputerDesktopIcon,
};
function getIconComponent(iconName) {
  return icons[iconName] || null;
}
</script>

<style lang="postcss">
.sidebar {
  @apply freelance-bg-700 freelance-border-action dark:border-transparent border hover:freelance-border-action;
}
.sidebar:hover .sidebar-bubble::after {
  @apply freelance-border-action;
}
.sidebar-bubble::after {
  @apply hidden sm:block absolute freelance-border-action border dark:border-transparent w-10 z-10 h-10 transform rotate-45 -right-5 bottom-20 freelance-bg-700;
  content: "";
  border-bottom: 0px;
  border-left: 0px;
}
.languageButton {
  @apply freelance-text-action  my-2 border-t-2 pt-4 font-bold hover:underline block text-center;
}
</style>
